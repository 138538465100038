.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container {
  margin-top: 20px;
}

.table-cell-centered {
  text-align: center;
}

.datos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.datos input {
  width: 300px;
  height: 30px;
  font-size: 20px;
  padding-left: 10px;
  border-radius: 5px;
  margin: 5px;
}

.datos button {
  width: 320px;
  height: 50px;
  margin-top: 5px;
}
.btn btn-secondary {
  margin-top: 1000px;
}
